<template>
  <div id="page-customer-list">
    <div class="vx-card p-6">
      <!-- Header -->
      <div class="flex justify-between flex-wrap items-center">

        <!-- Search Form -->
        <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />

        <!-- Add Button -->
        <vs-button color="primary" icon-pack="feather" icon="icon-plus" class="mr-4" @click="addItem">Add Item</vs-button>
      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="itemsData"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="false"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
      >
      </ag-grid-vue>

      <div id="footer" class="grid grid-cols-2">
        <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                }}
                -
                {{
                  itemsData.length - currentPage * paginationPageSize > 0
                    ? currentPage * paginationPageSize
                    : itemsData.length
                }}
                of {{ itemsData.length }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                <span>25</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </div>

      <vs-pagination :total="getTotalPage" :max="7" v-model="currentPage" />
      </div>
    </div>

    <!--  Popup Customer  -->
    <vs-popup classContent="popup-example" :title="`${mode} item`" :active.sync="popup">

      <div class="vx-row">
        <div class="vx-col md:w-1/1 w-full">
          <vs-input class="w-full mb-3" :label="$i18n.t('Name')" v-model="form.name" />
        </div>

        <div class="vx-col md:w-1/1 w-full">
          <vs-input class="w-full mb-3" :label="$i18n.t('NPWP')" v-model="form.npwp" />
        </div>

        <div class="vx-col md:w-1/1 w-full">
          <label for="" class="vs-input--label">{{$i18n.t('Address')}}</label>
          <vs-textarea rows="6" class="w-full mb-3" v-model="form.address" />
        </div>
      </div>

      <div class="mt-5">
        <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-save" @click="storeItem">
          {{ $i18n.t('Save') }}
        </vs-button>
      </div>

    </vs-popup>
  </div>

</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'

import axios from '@/axios';

// Store Module
import moduleUserManagement from '@/store/user-management/moduleUserManagement.js'

// Cell Renderer
import CellRendererLink from './cell-renderer/CellRendererLink.vue'
import CellRendererStatus from './cell-renderer/CellRendererStatus.vue'
import CellRendererVerified from './cell-renderer/CellRendererVerified.vue'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'
import CellRendererFile from './cell-renderer/CellRendererFile.vue'


export default {
  components: {
    AgGridVue,
    vSelect,

    // Cell Renderer
    CellRendererLink,
    CellRendererStatus,
    CellRendererVerified,
    CellRendererActions,
    CellRendererFile
  },
  data () {
    return {
      loaded: false,
      searchQuery: '',
      year: '2020',
      yearOptions: ['2015', '2016', '2017', '2018', '2019', '2020'].reverse(),
      form: {},
      popup: false,
      mode: 'add',
      itemSelected: false,

      // AgGrid
      gridApi: null,
      getTotalPage: 0,
      gridOptions: {
        pagination: true,
        onPaginationChanged: this.onPaginationChanged,
      },
      itemsData: [],
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t('Name'),
          field: 'name',
          filter: true,
          width: 280,
          cellClass: 'text-center',
        },
        {
          headerName: this.$i18n.t('Address'),
          field: 'address',
          filter: true,
          width: 400,
          wrapText: true,
          autoHeight: true,
          cellStyle (params) {
            return {'line-height': 1.5}
          }
        },
        {
          headerName: this.$i18n.t('NPWP'),
          field: 'npwp',
          filter: true,
        },
        {
          headerName: 'Action',
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            editItem: this.editItem.bind(this),
            getData: this.getData.bind(this),
            deleteItem: this.deleteItem.bind(this)
          },
          headerClass: 'text-center',
          cellClass: 'text-center'
        }
      ],
    }
  },
  computed: {
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    }
  },
  methods: {
    loading () {
      this.loaded = false;

      this.$vs.loading({
        type: 'border',
        color: 'primary',
        text: `Wait a minute, It's getting data.`
      })
    },
    onPaginationChanged(){
      return this.getTotalPage = this.gridOptions.api.paginationGetTotalPages()
    },
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },
    getData () {
      let params = {

      };

      axios.get('/customer', {params}).then(resp => this.itemsData = resp.data.map((customer) => {
        if(!customer.name){
          customer.name = '-';
        }
        if(!customer.address){
          customer.address = '-';
        }
        if(!customer.npwp){
          customer.npwp = '-';
        }

        return customer;
      }) ).catch(err => {
        this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })
      })
      .finally(() => {
        this.onLoaded();
      })
    },
    addItem () {
      this.popup = true;
      this.mode = 'add';
    },
    editItem (item) {
      this.popup = true;
      this.mode = 'edit';
      this.itemSelected = item;

      this.form = {
        name: item.name === '-' ? '' : item.name,
        npwp: item.npwp === '-' ? '' : item.npwp,
        address: item.address === '-' ? '' : item.address
      };
    },
    storeItem () {
      if (this.mode == 'add') {
        let body = {
          name: this.form.name,
          address: this.form.address,
          npwp: this.form.npwp
        };

        axios.post('/customer', body).then(resp => {
          this.popup = false;

          this.$swal({
            title: 'Successful',
            text: resp.data.message,
            icon: 'success'
          }).then(() => {
            this.getData();
          });
        }).catch(err => {
          this.$vs.notify({
            title:'Opps something error',
            text: err.data.message,
            color:'danger'
          })
        })
      }
      else if (this.mode == 'edit') {
        let body = {
          name: this.form.name,
          npwp: this.form.npwp,
          address: this.form.address,
          _method: 'PUT',
        };

        axios.post('/customer/' + this.itemSelected.id, body).then(resp => {
          this.popup = false;

          this.$swal({
            title: 'Successful',
            text: resp.data.message,
            icon: 'success'
          }).then(() => {
            this.getData();
          });
        }).catch(err => {
          this.$vs.notify({
            title:'Opps something error',
            text: err.data.message,
            color:'danger'
          })
        })
      }

      this.mode = false;
    },
    deleteItem (item) {
      axios.delete('/customer/' + item.id).then(resp => {
        this.$swal({
          title: 'Successful',
          text: resp.data.message,
          icon: 'success'
        }).then(() => {
          this.getData();
        });
      }).catch(err => {
        this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })
      })
    },

    onLoaded(){
      this.loaded = true;
      this.$vs.loading.close();
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }

    this.gridApi.sizeColumnsToFit()
  },
  watch: {
    year () {
      this.getData();
    },
    $route () {
      this.getData();
    },
    popup () {
      if (!this.popup) {
        this.form = {};
      }
    }
  },
  created () {
    this.loading();
    this.getData();
  }
}

</script>

<style lang="scss">
#page-customer-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }

  .ag-row .ag-cell {
    display: flex;
    align-items: center;
  }

  .ag-row .text-right {
    flex-direction: row-reverse;
  }

  .ag-cell-wrap-text {
    word-break: break-word;
  }
}
</style>
